import React, { Component } from 'react'
import styles from './WorkflowForm.module.css'
import WorkflowStatus from './WorkflowStatus'
import WorkflowResult from './WorkflowResult'
import Axios from '../../components/Axios'
import { Link, searchParams } from 'react-router-dom'
import WorkflowApprovalDialog from './WorkflowApprovalDialog'
import WorkflowDialogBox from './WorkflowDialogBox';
import WorkflowPostEventDialog from './WorkflowPostEventDialog'
import DatePicker from 'react-datepicker';
import axios from 'axios'
import ReportUI from '../Reporting/ReportUI';
import CancelationConfirmationDialog from './CancelationConfirmationDialog'
import Pagination from './pagination/Pagination';

class Workflow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            view: '',
            isMarketOps: false,
            isAdmin: false,
            isEDAdmin: false,
            isOps: false,
            sortby: 2,
            reverse: true,
            user: this.props.user,
            statusFilter: '',
            submitterFilter: '',
            submitDateFilterMin: null,
            submitDateFilterMax: null,
            startDateFilterMin: null,
            startDateFilterMax: null,
            endDateFilterMin: null,
            endDateFilterMax: null,
            nameFilter: '',
            programFilter: '',
            amountFilter: '',
            payMethodFilter: '',
            caret: (<i className="fas fa-caret-down"></i>),
            resultsPerPage: 20,
            currentPage: 0,
            totalResults: 0

        };
    }

    componentDidMount() {
        let json = {};
        json.user = this.props.user;

        Axios('/api/workflow/get-my-workflows', json, (data) => {

            this.setState({ data: data.data, isAdmin: data.IsAdmin, isMarketOps: data.IsMarketOps, isEDAdmin: data.IsEDAdmin, totalResults:data.data.length });

        });

        var idString = this.props.location.search;

        if (idString.includes('id')) {

            this.showStatus(idString.split('=')[1]);
        }
    }


    


    approveWorkflow(id, status, orgName, program, start, notes, rep) {

        var isFinalApproved = false;


        var json = {};
        var approver_json = {};

        json.UserName = this.props.user.email;
        json.RoleName = this.props.user.role;
        json.InstanceID = id;
        json.RequestNote = notes;
        json.TransactionType = status;

        var approver_json2 = {};
        approver_json2.workflow_id = id;
        approver_json2.user = this.props.user;



        Axios('/api/workflow/approve-workflow', json, () => {

            approver_json.workflow_id = id;

            approver_json.user = this.props.user;

            if (status == 'REJECTED') {


                let email_json = {};


                email_json.to = rep + "@Incyte.com";
                email_json.subject = "An exhibit and display request has been REJECTED";
                    email_json.body = "<p>The exhibits and display request beginning on " + start + " has been rejected.  Please review, edit and resubmit if appropriate. </p> " +
                        " <p> <b>ORG NAME: </b> " + orgName + "<br/><b>PROGRAM: </b> " +
                        program + "<br/><b>REJECTION REASON:</b><br /> " +
                        notes + "</p><br/><p>Click on the following link to access the application: <a href='https://incyte-mdm-portal.bellcyn.com/workflow/?id={id}'>Exhibits & Displays Approval.</a></p><br/><p><i><b>Note:</b> This is system generated mail. Please do not reply. If you require any further assistance, please email exhibits.displays@incyte.com.</i></p>";


                Axios('/api/workflow/send-email', email_json, () => {

                });


            }


            var statusjson = {};

            statusjson.workflow_id = id;
            axios.post('/api/workflow/get-workflow-status', statusjson)
                .then(function (statusData) {


                    var statusText = statusData.data;


                    console.log("statusText = " + statusText);

                    if (statusText == "APPROVED") {

                        var email_json4 = {};

                        email_json4.to = rep + "@Incyte.com";
                        email_json4.subject = "Action Required:  An exhibit and display request has been APPROVED";
                        email_json4.body = "<p>The exhibits and display request beginning on " + start + " has been approved. </p> " +
                            " <p> <b>ORG NAME: </b> " + orgName + "<br/><b>PROGRAM NAME: </b> " + program +
                            "<br/><b>START DATE: </b> " + start +
                            "<br/><br/><b>NEXT STEPS: </b> <br/><ul>" +
                            "<li>Submit T&E expense by attaching E&D approval email and receipt image in Concur.  If a check payment is required, you will need to log into SAP to submit a Non-Catalog Purchase Requisition for Services check request. http://incyte-1.procurement3.Ariba.com</li>" +
                            "<li>Order a table top display by clicking this link https://incyte.sparkslink.com/store-login.  Use Incyte email address as username.  First time users will need to create a password.</li>" +
                            "</ul></p><br/><p>Click on the following link to access the application: <a href='https://incyte-mdm-portal.bellcyn.com/workflow/?id={id}'>Exhibits & Displays Approval.</a></p><br/><p><i><b>Note:</b> This is system generated mail. Please do not reply. If you require any further assistance, please email exhibits.displays@incyte.com</i></p>";


                        Axios('/api/workflow/send-email', email_json4, () => {

                        });





                    } else {




                        axios.post('/api/workflow/get-next-two-approvers', approver_json2)
                            .then(function (approverData) {

                                let email_json6 = {};

                                let approverName = approverData.data.current_approver_name;
                                let approverEmail = approverData.data.current_approver_email;
                                let requestor = approverData.data.requestor_email;

                                email_json6.to = approverEmail;
                                email_json6.subject = "Action Required:  An exhibit and display request has been submitted for your APPROVAL";
                                email_json6.body = "<p>An exhibit and display request has been submitted from " + requestor +
                                    " that requires Your approval.  Please review and respond within three business days. <br /> <b>ORG NAME: </b>" + orgName + "<br/><b>PROGRAM NAME: </b> " + program + "<br /><b>NOTES: </b>" +
                                    notes + "</p> <br/><p>Click on the following link to access the application: <a href='https://incyte-mdm-portal.bellcyn.com/workflow/?id={id}'>Exhibits & Displays Approval.</a></p><br/><p><i><b>Note:</b> This is system generated mail. Please do not reply.  If you require any further assistance, please email exhibits.displays@incyte.com.</i></p>";

                                if (status != 'REJECTED') {

                                    Axios('/api/workflow/send-email', email_json6, () => {

                                    });
                                }

                            });


                    }

                });






        });


        this.setState({ view: '' })
        // window.location.reload(false);
    }
    showApproveDialog(id, status, org, program, start, rep) {

        var app = "";

        if (status == "APPROVED") {
            app = "APPROVE";
        }
        else {
            app = "REJECT";
        }
        this.setState({ view: (<WorkflowApprovalDialog id={id} approve={status} title={app} start={start} org={org} rep={rep} program={program} submit={this.approveWorkflow.bind(this)} close={this.close.bind(this)} />) });

    }
  
    editWorkflow(id, org_id) {

        this.setState({ view: (<WorkflowDialogBox is_edit={true} id={id} org_id={ org_id } close={this.close.bind(this)} user={this.props.user} />) });
    }
    approveCancelation(id) {
        let json = {};
        json.EVENT_ID = id;
        json.UserName = this.state.user.userName;
        json.RoleName = this.state.user.role;

        Axios('/api/workflow/cancel-workflow', json, () => {
            this.setState({ view: '' })
        });
        window.location.reload(false);
    }
    cancelEvent(id, orgName, orgProgram) {
        //let json = {};
        //json.EVENT_ID = id;

        //Axios('/api/workflow/cancel-workflow', json, () => {
        //    this.setState({ view: '' })
        //});
        //window.location.reload(false);

        this.setState({ view: (<CancelationConfirmationDialog id={id} orgName={orgName} programName={orgProgram} cancelCancelation={this.close.bind(this)} approveCancelation={this.approveCancelation.bind(this)} />) });

    }
    showStatus(id) {
        this.setState({ view: (<WorkflowStatus user={this.props.user} cancelEvent={this.cancelEvent.bind(this)} approve={this.showApproveDialog.bind(this)} id={id} close={this.close.bind(this)} />) });
    }
    close() {
        this.setState({
            view: ''
        })

    }
    getViewButtonStatus(data) {

        var role = this.props.user.role;
        var isAdmin = this.props.user.isAdmin;
        var isEDAdmin = this.props.user.isEDAdmin;

        if (role == "ADMIN") {
            isAdmin = true;
        }
        if (data.CURRENT_APPROVER_EMAIL == null) {
            return 'enabled';
        }

        var currentUserName = this.props.user.email.toLowerCase();
        var userText = data.CURRENT_APPROVER_EMAIL.toLowerCase();

        if (data.STATUS == "APPROVED" || data.STATUS == "CANCELED") {
            return 'enabled';
        }

        if (data.CURRENT_APPROVER_EMAIL && userText.includes(currentUserName) && data.STATUS != "REJECTED") {
            return 'greenbutton';
        }
        if (this.state.isEDAdmin) {
            return 'enabled';
        }

        //if (role == 'OHS') {
        //    return 'disabled';
        //}

        return 'enabled';
    }
    getButtonStatus(data) {

        var role = this.props.user.role;
        var isAdmin = this.props.user.isAdmin;
        var isEDAdmin = this.props.user.isEDAdmin;
        var currentUserName = this.props.user.email.toLowerCase();
        var subDate = new Date("January 01, 1971 00:00:00");


        if (subDate < subDate) {
            return 'disabled';
        }
        if (data.STATUS == "APPROVED" || data.STATUS == "CANCELED") {
            return 'disabled';
        }

        if (this.state.isEDAdmin) {
            return 'enabled';
        }

        //if (role == 'OHS') {
        //    return 'disabled';
        //}

        if (data.CURRENT_APPROVER_EMAIL && data.CURRENT_APPROVER_EMAIL.includes(currentUserName) && data.STATUS != "REJECTED") {
            return 'enabled';
        }
        return 'disabled';
    }

    getCloseoutStatus(data) {

        var isEDAdmin = this.state.isEDAdmin;
        var eventDT = new Date(data.END_DT);
        var _today = new Date();



        if (data.STATUS === "COMPLETED" || data.STATUS === "CANCELED" || data.STATUS === "REJECTED") {
            return 'disabled';

        }

        var isAfterEvent = false;
        if (eventDT < _today) {
            isAfterEvent = true;
        }

        if ((data.STATUS === "APPROVED" || data.STATUS === "OCCURRED") && this.props.user.email.includes(data.USER_ID) && isAfterEvent) {
            return 'enabled';

        }

        if (this.state.isEDAdmin && isAfterEvent) {
            return 'enabled';
        }

        return 'disabled';
    }
    getExportStatus(data) {
        var isAdmin = this.state.isAdmin;
        var isEDAdmin = this.state.isEDAdmin;

        if (isAdmin || isEDAdmin) {

            //user is submit user 
            return 'enabled';

        } else {
            return 'disabled';
        }
    }

    submitPostEvent(id, participation, partcomments, iscontractual, benefits, attendance, interactions, interaction_time, theater, theater_other, category) {

        let json = {};
        json.InstanceID = id;
        json.Participation = participation;
        json.ParticipationNotes = partcomments;
        json.Contractual = iscontractual;
        json.ContractualNotes = benefits;
        json.Attendance = attendance;
        json.Interactions = interactions;
        json.InteractionTime = interaction_time;
        json.Theaters = theater;
        json.TheaterOther = theater_other;
        json.Category = category;


        Axios('/api/workflow/update-post-event', json, (approverData) => {

        });

        this.setState({ view: '' })

        window.location.reload(false);

    }

    showCloseout(id) {

        this.setState({ view: (<WorkflowPostEventDialog close={this.close.bind(this)} user={this.props.user} submit={this.submitPostEvent.bind(this)} id={id} />) });

    }
    export(id) {

        let json = {};
        json.ID = id;

        //alert(id);
        //show the dialog here

      

        axios({
            method: 'post',
            responseType: 'arraybuffer', //Force to receive data in a Blob Format
            url: '/api/workflow/get_workflow_export',
            data: json
        }).then(res => {

            let newDate = new Date()
            let date = newDate.getDate();
            let month = newDate.getMonth();
            let year = newDate.getFullYear();
            let sec = newDate.getSeconds();
            let min = newDate.getMinutes();

            let fileName = date.toString() + month.toString() + year.toString() + min.toString() + sec.toString() + ".xlsx";
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}`;
            link.click();
        }).catch(error => {
            console.log(error.message);
        });

    }
    exportdata(reportID) {

        

        switch (reportID) {
            case 1:
               
                this.setState({ view: (<ReportUI title={'E&D Summary Report'} close={this.close.bind(this)} export={this.summaryexp.bind(this)} />) });
                 break;
            case 2:
                //title = 'E&D Detail Report';
                break;
           
            default:
                throw new Error(`Invalid report '${reportID}'`);
        }

     //   this.showReportDialog('E&D Summary Report');

        
    }
    summaryexp(to, from) {

        let json = {};
        json.Count = 100;
        json.FromDate = from;
        json.ToDate = to;

        axios({
            method: 'post',
            responseType: 'arraybuffer', //Force to receive data in a Blob Format
            url: '/api/workflow/get_workflow_summary_export',
            data: json
        }).then(res => {

            let newDate = new Date()
            let date = newDate.getDate();
            let month = newDate.getMonth();
            let year = newDate.getFullYear();
            let sec = newDate.getSeconds();
            let min = newDate.getMinutes();

            let fileName = date.toString() + month.toString() + year.toString() + min.toString() + sec.toString() + ".xlsx";
            const blob = new Blob([res.data], { type: 'application/octet-stream' })
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = `${fileName}`;
            link.click();
        }).catch(error => {
            console.log(error.message);
        });

    }
    getEditButtonStatus(data) {

        var role = this.props.user.role;
        var isAdmin = this.props.user.isAdmin;
        var isEDAdmin = this.props.user.isEDAdmin;
        var currentUserName = this.props.user.email;
        var subDate = new Date("January 01, 1971 00:00:00");
        var dataDate = new Date(data.SUBMIT_DATE); 
        //var subMgr = new Date(data.SUBMIT_DATE);

        if (dataDate < subDate) {
            return 'disabled';
        }
        if (this.state.isEDAdmin || this.state.isMarketOps) {
            return 'enabled';
        }
        switch (data.STATUS.toLowerCase()) {
            case "approved":
                return 'disabled';
                break;
            case "completed":
            case "canceled":
                return 'disabled';
                break;
            case "rejected":

                if (currentUserName.includes(data.USER_ID) || (role === "OBD" || role === "DBD")) {

                    //user is submit user / OBD or DBD
                    return 'enabled';

                } else {
                    return 'disabled';
                }
                break;
            default:


                if ((role === "OBD" || role === "DBD") &&  data.SUBMITTER_MGR) {

                    if (currentUserName.includes(data.SUBMITTER_MGR) && (currentUserName == data.CURRENT_APPROVER_EMAIL)) {


                        return 'enabled';


                    }
                    
                    


                }
                return 'disabled';
                break;
        }

    }
    filterData(data) {

        var statusFilter = this.state.statusFilter;
        var submitterFilter = this.state.submitterFilter.toLowerCase();
        var submitDateFilterMin = this.state.submitDateFilterMin;
        var submitDateFilterMax = this.state.submitDateFilterMax && new Date(this.state.submitDateFilterMax).setDate(this.state.submitDateFilterMax.getDate() + 1);
        var startDateFilterMin = this.state.startDateFilterMin;
        var startDateFilterMax = this.state.startDateFilterMax && new Date(this.state.startDateFilterMax).setDate(this.state.startDateFilterMax.getDate() + 1);
        var endDateFilterMin = this.state.endDateFilterMin;
        var endDateFilterMax = this.state.endDateFilterMax && new Date(this.state.endDateFilterMax).setDate(this.state.endDateFilterMax.getDate() + 1);
        var nameFilter = this.state.nameFilter.toLowerCase();
        var programFilter = this.state.programFilter.toLowerCase();
        var amountFilter = this.state.amountFilter;
        var payMethodFilter = this.state.payMethodFilter.toLowerCase();

        var filteredData = data.filter((row) => {
            let amount = Number.parseInt(row.AMOUNT, 10);
            let submitDate = new Date(row.SUBMIT_DATE);
            let startDate = new Date(row.START_DT);
            let endDate = new Date(row.END_DT);

            return (statusFilter.length === 0 || row.STATUS === statusFilter) &&
                row.SUBMIT_NAME.toLowerCase().includes(submitterFilter??"") && 
                ((submitDateFilterMin === null || submitDateFilterMax === null)|| (submitDate >= submitDateFilterMin && submitDate < submitDateFilterMax)) &&
                ((startDateFilterMin === null || startDateFilterMax === null)|| (startDate >= startDateFilterMin && startDate < startDateFilterMax)) &&
                ((endDateFilterMin === null || endDateFilterMax === null)|| (endDate >= endDateFilterMin && endDate < endDateFilterMax)) &&
                row.ORG_NAME.toLowerCase().includes(nameFilter??"") && 
                row.PROGRAM_TITLE.toLowerCase().includes(programFilter??"") &&
                (amountFilter.length === 0 || (amountFilter == "over" && amount >= 5000) || (amountFilter == "under" && amount < 5000)) &&
                row.PAYMENT_METHOD.toLowerCase().includes(payMethodFilter??"")
        });

        return filteredData;
    }

    sortData(data, i) {
        let columns = ['STATUS', 'SUBMIT_NAME', 'SUBMIT_DATE', 'ORG_NAME', 'PROGRAM_TITLE', 'AMOUNT', 'PAYMENT_METHOD', 'START_DT', 'END_DT'];
        var isReversed = this.state.reverse;

        var sortedData = data.sort((a, b) => {
            let column = columns[i];
            let transform; //this holds a function that will pre-process the data so it can be compared
            switch (column) {
                case 'SUBMIT_DATE':
                case 'START_DT':
                case 'END_DT':
                    //datetimes
                    transform = (raw) => raw; //datetime already implements the '>', '<', '=' operators
                    break;
                case 'AMOUNT':
                    //numbers
                    transform = (raw) => parseInt(raw); //data comes in as string, need to convert to number
                    break;
                default:
                    //strings
                    transform = (raw) => raw?.toLowerCase(); //business rule: sort is case-insensitive
            }

            if (transform(a[column]) < transform(b[column])) {
                return isReversed ? 1 : -1;
            }
            if (transform(a[column]) >= transform(b[column])) {
                return isReversed ? -1 : 1;
            }
        });

        return sortedData;

    }
    sort(index) {

        var oldIndex = this.state.sortby;
        var flip = this.state.reverse;

        if (oldIndex == index) {
            flip = !flip;
        }

        let _caret = flip ? (<i className="fas fa-caret-down"></i>) : (<i className="fas fa-caret-up"></i>);



        this.setState({ sortby: index, reverse: flip, caret: _caret });
    }

    render() {

        let results = []
        let isOdd = false;
        var sortByIndex = this.state.sortby;
        var totalSorted = this.state.totalResults;

       
        if (this.state.data) {

            var filteredData = this.filterData(this.state.data);
            var preSortedData = this.sortData(filteredData, sortByIndex);

            var loopStart = Math.round(this.state.currentPage * this.state.resultsPerPage)
            var loopEnd = Math.round((this.state.currentPage * this.state.resultsPerPage) + this.state.resultsPerPage)

            if (loopEnd > this.state.totalResults) {
                loopEnd = this.state.totalResults;
            } 

            if (preSortedData.length < totalSorted) {
                totalSorted = preSortedData.length;
                loopEnd = totalSorted;
            }

            for (let i = loopStart; i < loopEnd; i++) {


                var disableEdit = this.getEditButtonStatus(preSortedData[i]);
                var disableCloseout = this.getCloseoutStatus(preSortedData[i]);
                var diableExport = this.getExportStatus(preSortedData[i]);
                var changeColor = this.getViewButtonStatus(preSortedData[i]);

                results.push(<WorkflowResult key={i}
                    diableCloseout={disableCloseout}
                    showCloseout={this.showCloseout.bind(this)}
                    data={preSortedData[i]}
                    edit={this.editWorkflow.bind(this)}
                    showStatus={this.showStatus.bind(this)} odd={isOdd}
                    disableApprove={'false'}
                    disableEdit={disableEdit}
                    changeViewColor={changeColor}
                    diableExport={diableExport}
                    export={this.export.bind(this)}
                />);
                isOdd = !isOdd;

            }

        }
        var exportButton = this.state.isAdmin || this.state.isEDAdmin ? (<button className={styles.newbutton} onClick={() => { this.exportdata(1) }}>EXPORT</button>) : "";

        return (




            <div className={styles.container}>

                {this.state.view}

                <div className={styles.TopBar}>
                    <div className={styles.title}>Exhibits & Displays</div>

                </div>

                <div>
                    <Link to='/hco'>
                        <button className={styles.newbutton} >CREATE NEW</button>
                    </Link>

                    {
                        exportButton
                    }


                </div>
                <br />
                <div className={styles.content}>

                    <div className={styles.titlerow}>
                        {/*     <div className={styles.titleitem}>ID</div>*/}
                        <div className={styles.titleitemstatus} onClick={() => { this.sort(0) }} >STATUS<div className={this.state.sortby === 0 ? [styles.down, styles.sortBy].join(' ') : styles.down}>{this.state.caret}</div> </div>
                        <div className={styles.titleitemstatus} onClick={() => { this.sort(1) }} >SUBMITTER<div className={this.state.sortby === 1 ? [styles.down, styles.sortBy].join(' ') : styles.down}>{this.state.caret}</div> </div>
                        <div className={styles.titleitemxshort} onClick={() => { this.sort(2) }} >SUBMIT DATE<div className={this.state.sortby === 2 ? [styles.down, styles.sortBy].join(' ') : styles.down}>{this.state.caret}</div> </div>
                        <div className={styles.titleitemlong} onClick={() => { this.sort(3) }} >ORGANIZATION<div className={this.state.sortby === 3 ? [styles.down, styles.sortBy].join(' ') : styles.down}>{this.state.caret}</div> </div>

                        <div className={styles.titleitemlong} onClick={() => { this.sort(4) }} >PROGRAM TITLE<div className={this.state.sortby === 4 ? [styles.down, styles.sortBy].join(' ') : styles.down}>{this.state.caret}</div> </div>
                        <div className={styles.titleitemstatus} onClick={() => { this.sort(5) }} >AMOUNT<div className={this.state.sortby === 5 ? [styles.down, styles.sortBy].join(' ') : styles.down}>{this.state.caret}</div> </div>
                        <div className={styles.titleitemstatus} onClick={() => { this.sort(6) }} >PAY METHOD<div className={this.state.sortby === 6 ? [styles.down, styles.sortBy].join(' ') : styles.down}>{this.state.caret}</div> </div>
                        <div className={styles.titleitemxshort} onClick={() => { this.sort(7) }} >START DATE<div className={this.state.sortby === 7 ? [styles.down, styles.sortBy].join(' ') : styles.down}>{this.state.caret}</div> </div>
                        <div className={styles.titleitemxshort} onClick={() => { this.sort(8) }} >END DATE<div className={this.state.sortby === 8 ? [styles.down, styles.sortBy].join(' ') : styles.down}>{this.state.caret}</div> </div>
                        <div className={styles.titleitemsap} >SAP</div>
                        <div className={styles.titleitemtin} >TIN</div>
                        <div className={styles.actiontitle}>ACTIONS</div>

                    </div>

                    <div className={styles.titlerow}>

                        <div className={styles.titleitemstatus} >
                            <select defaultValue={""} id={"status_select_box"} className={styles.searchdd}   onChange={() => {
                               var status = document.getElementById("status_select_box").value;
                               this.setState({ statusFilter: status })
                            }}>
                               <option></option>
                               <option >APPROVED</option>
                               <option >COMPLETED</option>
                               <option >OCCURRED</option>
                               <option >REJECTED</option>
                               <option >SUBMITTED</option>
                            </select>
                        </div>
                        <div className={styles.titleitemstatus} >
                            <input type="text" onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    this.setState({ submitterFilter: e.target.value });
                                }
                            }} /> 
                        </div>
                        <div className={styles.titleitemxshort} >
                        <DatePicker 
                            className={styles.reactdatetime}
                            selected={this.state.submitDateFilterMin} 
                            selectsRange
                            startDate={this.state.submitDateFilterMin}
                            endDate={this.state.submitDateFilterMax}
                            isClearable={true}
                            onChange={(event) => {
                                this.setState({
                                    submitDateFilterMin: event[0],
                                    submitDateFilterMax: event[1]
                                });   
                            }}  
                            maxDate={new Date()} 
                        />
                        </div>
                        <div className={styles.titleitemlong} >
                            <input type="text" onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                    this.setState({ nameFilter: e.target.value });
                                }
                            }} /> 
                        </div>

                        <div className={styles.titleitemlong} ><input type="text" onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                this.setState({ programFilter: e.target.value });
                                  
                            }
                        }} /> </div>
                        <div className={styles.titleitemstatus} >
                            <select className={styles.searchdd}   defaultValue={""} id={"amount_select_box"} onChange={() => {
                                    var amount = document.getElementById("amount_select_box").value;
                                    this.setState({ amountFilter: amount }) 
                                }}>
                               <option ></option>
                               <option value='under'>UNDER $5000</option>
                               <option value='over'>OVER $5000</option>


                            </select>
                        </div>
                        <div className={styles.titleitemstatus} >
                            <select className={styles.searchdd}   defaultValue={""} id={"paymethod_select_box"} onChange={() => {
                                    var paymethod = document.getElementById("paymethod_select_box").value;
                                    this.setState({ payMethodFilter: paymethod }) 
                                }}>
                               <option ></option>
                                   <option value='check'>CHECK</option>
                                   <option value='credit'>CREDIT</option>
                            </select>
                        </div>
                        <div className={styles.titleitemxshort} >
                            <DatePicker 
                                className={styles.reactdatetime}
                                selected={this.state.startDateFilterMin} 
                                selectsRange
                                startDate={this.state.startDateFilterMin}
                                endDate={this.state.startDateFilterMax}
                                isClearable={true}
                                onChange={(event) => {
                                    this.setState({
                                        startDateFilterMin: event[0],
                                        startDateFilterMax: event[1]
                                    });   
                                }}
                            />
                        </div>
                        <div className={styles.titleitemxshort} >
                            <DatePicker 
                                className={styles.reactdatetime}
                                selected={this.state.endDateFilterMin} 
                                selectsRange
                                startDate={this.state.endDateFilterMin}
                                endDate={this.state.endDateFilterMax}
                                isClearable={true}
                                onChange={(event) => {
                                    this.setState({
                                        endDateFilterMin: event[0],
                                        endDateFilterMax: event[1]
                                    });   
                                }}
                            />
                        </div>
                        <div className={styles.titleitemsap}></div>
                        <div className={styles.titleitemtin}></div>
                        <div className={styles.actiontitle}></div>

                    </div>
                    {results.length > 0 ? results : this.state.data?.length > 0 ? "No results found" : "Loading"}


                    <Pagination
                        currentPage={this.state.currentPage}
                        resultsPerPage={this.state.resultsPerPage}
                        totalResults={totalSorted}
                        button={true}
                        buttonName={'ADD EVENT'}
                        buttonIcon={ <i className={["fas", "fa-plus", styles.Icon].join(' ')}></i> }
                        buttonClick={() => {
                            
                        }}
                        setProperties={(currentPage, resultsPerPage) => {
                            this.setState({ currentPage: currentPage, resultsPerPage: resultsPerPage })
                           
                            
                        }} />
                </div>
            </div>


        )
    }
}

export default Workflow;
